import React from 'react'
import styles from './../../styles/modules/modal.module.scss'

const Modal = ({title, handleClose, subject = "Subject"}) => {

  return <div className={styles.modalContainer}>
    <div className={styles.modal}>
      <h2>{title}</h2>
      <label htmlFor="">Full name</label>
      <input type="text"/>
      <label htmlFor="">Email Address</label>
      <input type="email"/>
      <label htmlFor="">{subject}</label>
      <input type="text"/>
      <label htmlFor="">Email body</label>
      <textarea name="" id="" cols="30" rows="10"></textarea>
      <button type="button" className="small">Send message</button>
      <button type="button" className={`outline small ${styles.closeBtn}`} onClick={() => handleClose()}>Close</button>
    </div>
  </div>
}

export default Modal
